import React from "react";
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import tapdaq from "../images/projects/tapdaq-ad-mediation-machine-learning.png";
// import nick_reffitt from "../images/people/nick_reffitt.jpg";
import trouva from '../images/projects/trouva-product-discovery-recommendation-engine.jpg';
import picfair from "../images/projects/picfair-photo-quality-scoring-for-search.png";
import rentify from "../images/projects/rentify-property-trends-data-product.png";
import channel4 from "../images/projects/channel4-topic-modeling-for-conversation-themes.png";

import Layout from '../components/layout'

export default () => (
  <Layout>

	<h2>Case studies</h2>


	<h3>Tapdaq</h3>

	<p><OutboundLink href='https://tapdaq.com/'>Tapdaq</OutboundLink>'s mediation product allows app publishers to show ads from any ad network. I designed and built a machine learning system that selects the best paying network for each ad shown.</p>

	<p>The system uses factors like ad network performance history, app type, device and user details to predict which network will give the best payout. It makes a new decision for each ad impression in each app, so publishers earn the most revenue possible from their portfolios.</p>

	<img src={tapdaq} className="center" alt="Tapdaq ad mediation machine learning system" />

	<p>Huge volumes of ad impressions and events occur across all of Tapdaq's apps, all of which are captured and used to make predictions. I designed and built a collection of microservices, using technologies including Apache Kafka and Google BigQuery, to provide large scale machine learning with guaranteed response times.</p>

	<p>The nature of the prediction problem required a novel combination of machine learning techniques, including reinforcement learning and supervised learning.</p>

    <p>"We were delighted to have Ollie join our team to help us understand a critical part of our new product.</p>

    <p>His careful planning, clear communication and technical ability surpassed all of our expectations, not only that, he gelled in well with the team right away, and was able to work side-by-side with our engineers to integrate his efforts into our existing infrastructure.</p>

    <p>Ollie's work has had a radical impact on our business, and I would certainly recommend him to anyone who wants to shift the needle in terms of business value."</p>

    <p><OutboundLink href="https://twitter.com/nickreffitt">Nick Reffitt</OutboundLink>, CTO</p>



	<h3>Trouva</h3>

	<p><OutboundLink href='https://www.trouva.com/'>Trouva</OutboundLink> is an award winning, highly curated online marketplace for independent homeware and lifestyle boutiques. Working with their team, I designed and built a new product discovery feature and recommendation system to improve user experience and drive engagement, and helped create an internal boutique analytics product.</p>

	<img src={trouva} className="center" alt="Trouva product discovery recommendation engine" />

	<p>The recommendation system uses a combination of user behaviour and enriched boutique data to learn and produce optimal recommendations for users. A machine learning subsystem determines similarity between boutique styles and uses it in the recommendation process, capturing and scaling Trouva’s curatorial vision.</p>

	<p>A flexible and extensible architecture allows the system to make recommendations from any kind of entity (boutiques, products, categories etc.) to any other kind, letting Trouva make personalised recommendations across all parts of the product and in their marketing.</p>



	<h3>Picfair</h3>

	<p>I designed and built a machine learning system to rate photo quality and factor it into search results, giving <OutboundLink href="https://www.picfair.com/">Picfair</OutboundLink> a market-leading photography search engine.</p>

	<img src={picfair} className="center" alt="Picfair photo quality scoring for search" />

	<p>The machine learning system is deployed into production as a scalable microservice, so as Picfair grows they can just add more servers to handle incoming images. It's seamlessly integrated into the existing tech stack, with testing, server monitoring, a clean API and interfaces for the Rails and Elasticsearch codebase.</p>



	<h3>Rentify</h3>

	<p>I designed and built an interactive visualisation of London's property market, a system to find stories from rental trends each month, and data-driven marketing pages for <OutboundLink href="https://www.rentify.com/">Rentify</OutboundLink>'s marketing team.</p>

	<img src={rentify} className="center" alt="Rentify property trends data product" />

	<p>All of these tools are powered by a central data product. Combining Rentify's exclusive lettings data with 3rd party sources including London Datastore and Land Registry records, it creates a rolling, proprietary analysis of the London property and rental market.</p>



	<h3>Channel 4</h3>

	<p><OutboundLink href="http://www.channel4.com/programmes/the-promise">The Promise</OutboundLink> was a powerful historical drama set in Israel and Palestine. Channel 4 expected strong and wide ranging reactions to the program, and wanted a way to show this conversation while maintaining editorial guidelines and balance.</p>

	<p>I designed a <OutboundLink href="http://journalofdigitalhumanities.org/2-1/topic-modeling-a-basic-introduction-by-megan-r-brett/">topic modeling</OutboundLink> system to group the conversation into themes in a way that reflected Channel 4's editorial policy, built an interactive visualization to let viewers see and explore the conversation, and designed a server architecture to make it work at scale.</p>

	<img src={channel4} className="center" alt="Channel 4 topic modeling for conversation themes" />

	<p>Behind the scenes, a data service collects tweets matching the hashtag #c4thepromise. Natural language processing techniques clean the text content, then store the words and sentences in a graph data structure. The topic modeler processes the graph and exports an optimised data format to a separate web app, which serves the visualization and client data requests at scale.</p>



	<h3>Other work</h3>

	<ul style={{
		listStyle: 'none',
		paddingLeft: 0,
		marginLeft: 0
	}}>
	    <li>
	        <strong>NHS</strong> Clinician survey and reporting data product
	    </li>
	    <li>
	        <strong>O2</strong> Social commerce tracking system and social data analysis
	    </li>
	    <li>
	        <strong>Mercari</strong> Machine learning strategy consultancy
	    </li>
	    <li>
	        <strong>Social Chain</strong> Social data strategy
	    </li>
	    <li>
	        <strong>IG</strong> Bespoke training for PPC and RTB marketers
	    </li>
	    <li>
	        <strong>Futrli</strong> Machine learning for automated time series prediction
	    </li>
	    <li>
	        <strong>Silicon Milkroundabout</strong> Data-driven decision support tools for candidate vetting and scoring
	    </li>
	    <li>
	        <strong>Hire My Friend</strong> Marketplace analysis and automatic user segmentation
	    </li>
	    <li>
	        <strong>YouGov</strong> User behaviour analysis and data product roadmap
	    </li>
	    <li>
	        <strong>PeerIndex</strong> Personalised marketing automation and data product design
	    </li>
	    <li>
	        <strong>Airfinity</strong> Machine learning consultancy
	    </li>
	    <li>
	        <strong>QSA</strong> Data-driven marketing and visualisation
	    </li>
	    <li>
	        <strong>RSPCA</strong> Social data strategy presentation
	    </li>
	    <li>
	        <strong>Ribot</strong> Dashboard design workshop
	    </li>
	    <li>
	        <strong>University of Brighton</strong> Generative graphics and data-driven visualisation workshop
	    </li>
	</ul>

  </Layout>
);